// @font-face {
//   font-family: 'LasEnter';
//   src: local('LasEnter'), url('./las-enter-font/LasEnterPersonalUseOnly-D301.ttf') format('truetype')
// }

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  // background-color: #282c34;
  // color: azure;
  // background-color: black;

  transition: color 0.2s ease-out, background-color 0.2s ease-out;

  min-width: 300px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
