.App {
  text-align: center;
}

:root {
  --background: white;
  --onBackground: black;
  --primary: linear-gradient(-15deg, rgb(182, 182, 182) 20%, 40%, #e8e8e8 100%);
  --onPrimary: black;
  --onSecondary: black;
  --primaryColor: #3abb23;
  --colorTitle: linear-gradient(-15deg, rgb(182, 182, 182) 20%, 40%, #e8e8e8 100%);
  --primarySolid: #e8e8e8;

  --profileTextColor: black;

  --isDark: 0
}

.darkMode {
  --background: #212124;
  // --background: #282c34;
  // --primary: linear-gradient(-15deg, rgb(182, 182, 182) 20%, 40%, #e8e8e8 100%);
  // --primary: linear-gradient(-15deg, rgb(43, 85, 48) 20%, 40%, #4e9d72 100%);


  // --primary: linear-gradient(-15deg, rgb(35, 94, 115) 20%, 40%, #4d9a6f 100%);
  --primary: linear-gradient(-15deg, rgb(35, 94, 115) 20%, 40%, #4d9a97 100%);
  --primaryColor: #44b075;
  --onSecondary: white;
  --onBackground: white;
  --primarySolid: #4d9a97;
  --onPrimary: black;

  --profileTextColor: var(--colorTitle);

  --colorTitle: linear-gradient(-55deg, rgb(41, 167, 176) 20%, 40%, #75c2be 100%);

  --isDark: 1
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

body {
  h1, h2, h3 {
    letter-spacing: -0.05em;
  }
  h1 {
    font-size:2.0em;
  }
  h2 {
    font-size: 1.5em;
  }

  background-color: var(--background);
  color: var(--onBackground);
}

img, video {
  border-radius: 15px;
}

p, li {
  letter-spacing: -0.025em;
  line-height: 1.5em;
  font-weight: 500;
  font-size: 1.05em;
}

ul {
  padding: 0 1em;

  h2 {
    padding: 0;
    margin: 0 0 5px -15px;
    // margin: 0;
  }
}

a {
  display: inline-block;
  text-decoration: none;
  color: #35c1e4;
}

@mixin all-rules {

  &-list-title {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &-ref-link {
    display: inline-block;
    color: #35c1e4;
    text-decoration: none;
  }

  &-title-link {
    color: #35c1e4;
    text-decoration: none;
  }

  &-app-header {
    text-align: start;
    font-size: 1.5em;
    max-width: 1024px;
    line-height: 0.8;
    // height: 100px;
    padding-top: 10px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  &-space {
    &-between {
      justify-content: space-between;
    }
    &-evenly {
      justify-content: space-evenly;
    }
  }
  &-flex {
    display: flex;
    flex-direction: column;

    &-one {
      flex: 1;
    }

    &-zero {
      flex: 0;
    }

    &-row {
      display: flex;
      flex-direction: row;
    }

    &-col {
      display: flex;
      flex-direction: column;
    }
  }

  &-basic-container {

    // min-width: 250px;
    width: 100%;
    // min-height: 450px;
    border-radius: 15px;
    box-sizing: border-box;

    // padding: 20px 35px;
    // background-color: rgb(34, 33, 33);
    color:var(--onSecondary);

    z-index: 1;
    overflow:hidden;
    position: relative;
    // border: 1px solid rgb(34, 33, 33);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200%;
      z-index: -1;
      transform: translateY(20%);
      transition: transform filter 0.2s ease;
      // background: linear-gradient(-15deg, rgb(34, 33, 33) 10%, 40%, rgb(57, 62, 73) 100%);
      // background: linear-gradient(-15deg, rgb(33, 32, 32) 10%, 40%, rgb(59, 60, 64) 100%);
    }
    &:hover::before {
      transform: translateY(-20%);
      // background: linear-gradient(-15deg, rgb(34, 33, 33) 10%, 40%, #616a79 100%);
    }



    h1 {
      padding: 0;
      margin: 10px 0;
      z-index: 2;
      // color: white;
      // color: var(--primaryColor);

    }
  }

  &-profile-container {
    width: 300px;
    @include tablet-size {
      width: 100%;
    }
    img {
      box-shadow: 0 0 20px -10px var(--primarySolid);
    }
  }

  &-social-link {
    padding-right: 10px;
  }

  &-link-icon {
    &-light {
      filter: contrast(100) grayscale(1) invert(1)
    }

    &-dark {
      filter: contrast(100) grayscale(1)
    }

    filter: contrast(100) grayscale(1) invert(var(--isDark))
  }

  &-dark-container {

    // min-width: 250px;
    width: 100%;
    min-height: 450px;
    border-radius: 15px;
    box-sizing: border-box;

    padding: 20px 35px;
    // background-color: rgb(34, 33, 33);
    color:white;

    z-index: 1;
    overflow:hidden;
    position: relative;
    // border: 1px solid rgb(34, 33, 33);

    @include phone-size() {
      padding: 10px 25px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200%;
      z-index: -1;
      transition: transform 0.2s ease;

      // background: linear-gradient(-15deg, rgb(34, 33, 33) 10%, 40%, rgb(57, 62, 73) 100%);
      background: linear-gradient(-15deg, rgb(33, 32, 32) 10%, 40%, rgb(59, 60, 64) 100%);
    }
    &:hover::before {
      transform: translateY(-10%);
      // background: linear-gradient(-15deg, rgb(34, 33, 33) 10%, 40%, #616a79 100%);
    }



    h1 {
      padding: 0;
      margin: 10px 0;
      z-index: 2;
      // color: white;
      color: white;
      // color: var(--primaryColor);


    }
  }

  &-light-container {
    width: 100%;
    border-radius: 15px;
    box-sizing: border-box;

    padding: 20px 35px;

    z-index: 1;
    overflow:hidden;
    position: relative;
    color: var(--onPrimary);

    box-shadow: 0 0 20px -8px var(--primarySolid);

    @include phone-size() {
      padding: 10px 25px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200%;
      z-index: -1;
      transition: transform 0.2s ease;

      background: var(--primary);
      // background: linear-gradient(-15deg, rgb(170, 190, 222) 20%, 40%, #dae5f3 100%);

    }
    &:hover::before {
      transform: translateY(-0%);
      // background: linear-gradient(-15deg, rgb(34, 33, 33) 10%, 40%, #616a79 100%);
    }



    h1 {
      padding: 0;
      margin: 10px 0;
      z-index: 2;
      // color: black;
    }
  }

  &-title-chunky {
    font-size: 2.5em;
  }

  &-section-title {
    padding-top: 40px;
    padding-bottom: 10px;
    font-size: 2.75em;
  }

  &-color-title {
    background: var(--colorTitle);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-profile-text {
    background: var(--profileTextColor);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


  &-hero-box {
    justify-content: space-between;
    gap: 30px;
    @include phone-size {
      flex-direction: column-reverse;
      /* align-items: center; */
    }
  }

  &-main-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 1024px;
    margin: 0 auto;
    text-align: start;
    width: 90%;
  }

  &-content-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-center-x {
    justify-content: center;
  }
  &-center-y {
    align-items: center;
  }

  &-center-text {
    text-align: center;
  }

  &-padding {
    &-1 {
      padding: 10px;
    }
  }

  &-no-padding {
    padding: 0;
  }
  &-no-margin {
    margin: 0;
  }
}

@mixin phone {
  @media only screen and (max-width: 600px) { @include all-rules; @content; }
}
@mixin phone-size {
  @media only screen and (max-width: 600px) { @content; }
}
@mixin tablet {
  @media only screen and (max-width: 768px) { @include all-rules; @content; }
}
@mixin tablet-size {
  @media only screen and (max-width: 768px) { @content; }
}
@mixin desktop {
  @media only screen and (min-width: 992px) { @content; }
}

.hp {

  @include all-rules;

  &-tablet {
    @include tablet();
  }
  &-phone {
    @include phone();
  }

  &-theme-button {
    background-color: transparent;
    color: var(--onBackground);
    border: none;
    // flex: 1;
    display:flex;
  }
}

@mixin create-padding-rules($class, $style, $step, $max) {
  $i: 0;
  @while $i <= 10 {
    #{$class + $i} {
      #{$style}: #{($i * 10) + px};
    }
    $i: $i + 1;
  }
}

@include create-padding-rules('.hp-padding-left-', 'padding-left', 5, 50);
@include create-padding-rules('.hp-padding-right-', 'padding-right', 5, 50);
@include create-padding-rules('.hp-padding-top-', 'padding-top', 5, 50);
@include create-padding-rules('.hp-padding-bottom-', 'padding-bottom', 5, 50);
@include create-padding-rules('.hp-padding-', 'padding', 5, 50);

@include create-padding-rules('.hp-margin-left-', 'margin-left', 5, 50);
@include create-padding-rules('.hp-margin-right-', 'margin-right', 5, 50);
@include create-padding-rules('.hp-margin-top-', 'margin-top', 5, 50);
@include create-padding-rules('.hp-margin-bottom-', 'margin-bottom', 5, 50);
@include create-padding-rules('.hp-margin-', 'margin', 5, 50);

@include create-padding-rules('.hp-rounded-', 'border-radius', 5, 25);


.hp {
  &-profile-picture {
    object-fit: cover;
    // object-position: 0% 20%;
    width: 300px;
    height: 300px;

    @include phone-size() {
      max-width: 150px;
      max-height: 150px;
      /* width: 40%; */
      /* height: 40%; */
      /* height: auto; */
      /* justify-self: center; */
      border-radius: 40px;
      border: 4px var(--primarySolid) solid;
      /* align-self: center; */

      margin: 0;
      /* padding: 0; */

      /* &::before { */
        /* margin: 0; */
        /* padding: 0; */
      /* } */
      // width: auto;
      // object-fit: cover;
      // height: 300px;
    }
    // height: 300px;
  }
  &-profile-picture-container {
  }

  &-project-card-content-inline {
    display:flex;
    flex-direction: column;

    align-items: start;
    margin: 20px 0;

    @include tablet-size() {
      margin: 0 auto;
      // width: 100%;
    }

    img {
      width: auto;
      max-width: 350px;
      max-height: 350px;

      justify-self: center;


      &::before {
        width: auto;
        max-width: 400px;
        max-height: 400px;
      }

      @include phone-size() {
        width: 100%;
      }
    }

    video {
      max-height: 400px;
      max-width: 350px;
      width: auto;

      justify-self: center;


      @include phone-size() {
        // margin: 0 auto;
      }
    }
  }
  &-project-card-content {
    display:flex;
    flex-direction: column;
    margin: 20px auto;
    align-items: center;

    @include tablet-size() {
      margin: 0 auto;
      // width: 100%;
    }

    img {
      width: auto;
      max-width: 400px;
      max-height: 400px;

      justify-self: center;


      &::before {
        width: auto;
        max-width: 400px;
        max-height: 400px;
      }

      @include phone-size() {
        width: 100%;
      }
    }

    video {
      max-height: 400px;
      max-width: 350px;
      width: auto;

      justify-self: center;


      @include phone-size() {
        // margin: 0 auto;
      }
    }
  }
  &-project-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &-footer {
    padding-top: 20px;
    padding-bottom: 50px;
    div {
      padding: 5px 0;
    }
  }
}
